import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, useStaticQuery } from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

const BlogList = (props) => {
  const data = useStaticQuery(graphql`
    query BlogListQuery {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              publicationDate
              templateKey
              category
              background
              featuredimage {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            timeToRead
          }
        }
      }
    }
  `);
  const { edges: posts } = data.allMarkdownRemark;
  return (
    <div className="listBlock">
      {posts
        .sort((a, b) => {
          const dateAPieces = a.node.frontmatter.publicationDate.split("/");
          const dateBPieces = b.node.frontmatter.publicationDate.split("/");
          const dateA = new Date(
            dateAPieces[2],
            dateAPieces[1],
            dateAPieces[0]
          );
          const dateB = new Date(
            dateBPieces[2],
            dateBPieces[1],
            dateBPieces[0]
          );
          return dateA > dateB;
        })
        .map(({ node: post }) =>
          post.frontmatter.category == props.filter ||
          props.filter === "null" ? (
            <div className="blogItem" key={post.id}>
              <div>
                {post.frontmatter.featuredimage ? (
                  <div className="itemImg">
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: post.frontmatter.featuredimage,
                        alt: "${post.frontmatter.title}",
                      }}
                    />
                  </div>
                ) : null}
                <div className="itemContainer">
                  <div className="ItemTagDate">
                    <p style={{ background: post.frontmatter.background }}>
                      {post.frontmatter.category}
                    </p>
                    <span className="">{post.frontmatter.publicationDate}</span>
                  </div>
                  <div className="itemTitle">
                    <p>{post.frontmatter.title}</p>
                  </div>
                  <div className="itemTimeLink">
                    <p>{post.timeToRead} min de leitura</p>
                    <Link className="" to={post.fields.slug}>
                      ler post
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ) : null
        )}
    </div>
  );
};

export default BlogList;

BlogList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};
