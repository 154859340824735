import React, { useState } from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";

import Layout from "../../components/Layout";
import BlogList from "../../components/BlogList";

import * as S from "../../styles/blogPage/styles.js";

export const BlogIndexPage = ({ edges }) => {
  const [filter, setFilter] = useState("null");
  const categories = [];

  return (
    <Layout>
      <S.BlogHeader>
        <div className="title">
          <h2>Blog</h2>
          <div className="border"></div>
        </div>
      </S.BlogHeader>
      <div className="container">
        <S.Filter>
          <div className="filterP">
            <p>Filtrar por:</p>
          </div>
          <div className="categoryBtns">
            <a
              onClick={() => setFilter("null")}
              style={
                filter === "null"
                  ? {
                      background: "#272729",
                      color: "#F2F2F7",
                      fontWeight: "900",
                      borderColor: "#272729 ",
                    }
                  : {}
              }
            >
              Todos
            </a>
            {edges &&
              edges.map((edge) => {
                if (!categories.includes(edge.node.frontmatter.category)) {
                  categories.push(edge.node.frontmatter.category);
                  return (
                    <div className="" key={edge.node.id}>
                      <a
                        onClick={() =>
                          setFilter(edge.node.frontmatter.category)
                        }
                        style={
                          filter === edge.node.frontmatter.category
                            ? {
                                background: edge.node.frontmatter.background,
                                color: "#F2F2F7",
                                fontWeight: "900",
                                borderColor: edge.node.frontmatter.background,
                              }
                            : {}
                        }
                      >
                        {edge.node.frontmatter.category}
                      </a>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
          </div>
        </S.Filter>
        <S.BlogList>
          <BlogList filter={filter} />
        </S.BlogList>
      </div>
      <div className="blockForm">
        <div>
          <h2>Sobre qual tema você quer saber?</h2>
          <p>Nos envie sua sugestão</p>
        </div>
        <form action="/success3"  method="POST" name="suggestion" data-netlify="true">
          <input type="hidden" name="form-name" value="suggestion"/>
          <input name="Nome" type="text" className="inputText" placeholder="Nome" />
          <InputMask
            className="inputText"
            name="Telefone"
            placeholder="Whatsapp"
            mask="(99) 99999-9999"
            required
            maskChar={null}
          />
          <input name="Email" type="text" className="inputText" placeholder="Email" />
          <textarea
            name="Mensagem"
            className="inputText"
            rows="5"
            cols="33"
            placeholder="Mensagem"
          />
          <button className="btn">Enviar sugestão</button>
        </form>
      </div>
    </Layout>
  );
};

BlogIndexPage.propTypes = {
  background: PropTypes.string,
  category: PropTypes.string,
};

const BlogPage = ({ data }) => {
  const { edges } = data.allMarkdownRemark;

  return <BlogIndexPage edges={edges} />;
};

BlogPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      edges: PropTypes.object,
    }),
  }),
};

export default BlogPage;

export const pageQuery = graphql`
  query BlogIndexPage {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___publicationDate] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            background
            category
            publicationDate
          }
        }
      }
    }
  }
`;
