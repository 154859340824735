import styled from "styled-components";

export const BlogHeader = styled.div`
  padding-top: 86px;
  .title {
    margin: 0 auto;
    width: 115px;
  }
`;

export const BlogList = styled.section`
  padding: 68px 0 126px;
  @media only screen and (max-width: 1101px) {
    padding: 41px 0 70px;
  }
  @media only screen and (max-width: 650px) {
    padding: 49px 0 63px;
  }
  .listBlock {
    display: grid;
    grid-gap: 62px 80px;
    grid-template-columns: 1fr 1fr 1fr;
    @media only screen and (max-width: 1101px) {
      grid-gap: 52px;
      grid-template-columns: 1fr 1fr;
    }
    @media only screen and (max-width: 650px) {
      grid-gap: 24px;
      grid-template-columns: 1fr;
    }
  }
`;

export const Filter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 69px;
  @media only screen and (max-width: 835px) {
    flex-direction: column;
    width: 100%;
    padding-top: 34px;
  }
  .filterP {
    @media only screen and (max-width: 835px) {
      width: 100%;
    }
    p {
      padding-right: 13px;
      font-weight: normal;
      font-size: 16px;
      line-height: 120%;
      color: #a8a8aa;
      @media only screen and (max-width: 835px) {
        padding-right: 0;
        padding-bottom: 28px;
        text-align: left;
      }
    }
  }

  .categoryBtns {
    display: flex;
    align-items: center;
    @media only screen and (max-width: 835px) {
      flex-wrap: wrap;
    }
    p {
      @media only screen and (max-width: 835px) {
        margin: 0 16px 20px 0;
      }
    }
    a {
      padding: 6px 10px;
      margin-left: 32px;
      border: 2px solid #d4d5d7;
      box-sizing: border-box;
      border-radius: 4px;
      color: #a8a8aa;
      cursor: pointer;
      outline: none;
      @media only screen and (max-width: 835px) {
        display: block;
        margin: 0 16px 20px 0;
      }
    }
  }
`;
